<template>
  <div>
    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="Soberania e Segurança Alimentar Nutricional"
              title_alignment="start"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Quando se pensa nos impactos gerados pelo rompimento de uma
                  barragem, além de mortes, destruição e danos ambientais, é
                  importante considerar também o prejuízo na qualidade da
                  alimentação das atingidas e atingidos. Esse é um dos motivos
                  pelos quais se diz que os danos decorrentes do rompimento da
                  barragem de Fundão são continuados, já que as consequências de
                  uma alimentação inadequada ou insuficiente se prolongam no
                  tempo.
                </p>
                <p>
                  As comunidades atingidas, em sua maioria, plantavam e colhiam
                  seus próprios alimentos, sem a utilização de agrotóxicos,
                  criavam animais para consumo, pescavam nos rios, coletavam
                  lenha, plantas medicinais e madeira nas matas e frequentemente
                  trocavam alimentos entre a vizinhança, familiares e amigos. O
                  modo de vida rural mantido por essas comunidades garante
                  diversidade e acesso a alimentos saudáveis de qualidade
                  conhecida, e também autonomia das famílias. “Sempre coloquei
                  na mesa para a minha família mais de três tipos de
                  verdura.”<sup>1</sup>
                </p>
                <p>
                  No município de Mariana, após o desastre–crime, centenas de
                  famílias foram forçadas a sair de seus territórios e abandonar
                  suas casas, que, para muitos, é o lugar onde nasceram e
                  viveram toda a vida. A maioria possuía quintais produtivos,
                  cultivava hortas, pomares e plantava seus roçados. Da mesma
                  forma, tinham o costume de criar porcos, gado, galinhas
                  caipiras para consumo e para produção de ovos, bem como pescar
                  nos rios e córregos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="100%"
            max-height="60%"
            src="../../assets/conteudo_cultura_alimentar_02.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Horta em Paracatu de Baixo. Foto: Flora Passos.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mb-section">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            “Eu vendia queijo, leite, rapadura, milho, feijão. Tudo enquanto lá
            eu mexia. Porque eu criei meus filhos lá na roça mesmo, nós não
            comprava nada não, sabe? Tratei meus filhos e o resto eu vendia,
            né?!”<sup>2</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="90%"
            max-height="100%"
            src="../../assets/conteudo_cultura_alimentar_04.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Produtor de leite na comunidade de Gesteira. Foto: Tainara Torres.
          </p>
        </v-col>
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Em novembro de 2015, essas famílias são deslocadas
                  abruptamente para o modo de vida urbano, distante da natureza
                  e sem vínculo comunitário. Neste outro modo de vida, para se
                  ter acesso a comida e outros recursos essenciais, o dinheiro
                  se faz indispensável.
                </p>
                <p>
                  Um dos atingidos da comunidade de Bento Rodrigues relata que a
                  dinâmica de cultivar a terra mudou drasticamente após o
                  rompimento, atualmente vive na cidade e sente vontade de
                  plantar até na laje da casa por não ter mais acesso a terra.
                  Antes do rompimento, ele possuía diversos cultivos e produzia
                  grande parte dos alimentos consumidos pela família, atualmente
                  tem que se contentar com pequeno espaço de terra onde cultiva
                  uma horta. Este mesmo atingido sente saudades não só do
                  contato com a terra, mas também de cuidar de seus animais, dos
                  quais sempre cuidou com muito prazer. Atualmente, porém,
                  apenas visita suas criações, uma vez que estão sob os cuidados
                  da Samarco desde a data do desastre.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container mt-10 mb-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A variedade de espécies que eram cultivadas nos terrenos
                  contribuía para uma dieta alimentar diversificada e saudável,
                  complementando parte significativa das necessidades
                  nutricionais da família. O impacto na renda também era
                  positivo, já que o excedente da produção familiar necessária à
                  subsistência, em muitos casos, era comercializado ou trocado
                  com outras famílias agricultoras.
                </p>
                <p>
                  Atualmente, além do custo dos alimentos nos centros urbanos, a
                  procedência e qualidade são duvidosas e desconhecidas. Ou
                  seja, os infinitos impactos causados pelo rompimento da
                  barragem de Fundão implica também em uma perda imensurável da
                  soberania e segurança alimentar e nutricional4 que antes
                  existia nas comunidades atingidas:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="90%"
            max-height="100%"
            src="../../assets/conteudo_cultura_alimentar_05.png"
          ></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Produção de ovos de galinha caipira na comunidade de Gesteira. Foto:
            Tainara Torres.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mb-section">
      <v-row
        class="d-flex flex-column flex-md-row justify-space-between align-center"
      >
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-md-end align-md-end justify-center align-center border__image3"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            “Nós sempre vivemos da plantação, meu pai é trabalhador rural, minha
            mãe também, eu também, a gente sempre viveu do que plantava. [...]
            Como tinha um quintal muito grande todo mundo tinha coisa pra levar
            no fim de semana, todo mundo ia de carro cheio, fazia a feira,
            horta, ovo, esse trem todo, queijo que minha mãe fazia queijo.”5
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-md-start align-md-start border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15; color: white;" class="ma-0">
            “A gente tinha a renda da agricultura. Eu fazia queijos, cachaça,
            rapadura. A gente não comprava nada disso. Eu tinha horta, vendia
            verdura, tinha galinhas, vendia ovos... Hoje eu não tenho nada, tudo
            tenho que estar comprando.”<sup>3</sup>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container mb-section mt-section">
      <v-row class="d-flex justify-space-around">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Sendo assim, mais do que danos a bens materiais, tal como o
                  terreno em si, as populações atingidas pelo rompimento da
                  barragem lidam cotidianamente com danos ao direito a uma
                  alimentação nutricionalmente saudável, diversificada, que
                  respeite a cultura e o modo de viver das famílias, direito
                  humano básico e fundamental para uma vida digna. A alimentação
                  é mais que ingestão de nutrientes, congrega as práticas
                  alimentares às dimensões culturais e sociais daquela
                  comunidade, o que influencia a saúde e o bem-estar dos
                  indivíduos (BRASIL, 2014)<sup>6</sup>.
                </p>
                <p style="text-align: center;">
                  <strong>
                    Autores: Isabela Fabiana da Silva Ladeira <sup>7</sup>,
                    Rafael Gustavo F. Pereira <sup>8</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img
            max-width="90%"
            max-height="100%"
            src="../../assets/conteudo_cultura_alimentar_08.png"
          ></v-img>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Trecho de fala de uma atingida durante a oficina de
              Cartografia Social na comunidade Paracatu de Cima, em Mariana-MG.
            </p>
            <p>
              <sup>2</sup> Trecho de fala de uma atingida durante a oficina de
              Cartografia Social na comunidade Águas Claras, em Mariana-MG.
            </p>
            <p>
              <sup>3</sup> Fala da Sra. Maria Auxiliadora Arcanjo Tavares,
              moradora de Paracatu de Cima, extraída do Jornal Sirene Ed. 61 -
              maio/2021.
            </p>
            <p>
              <sup>4</sup> Entende-se por soberania alimentar, de acordo com o
              Centro de Referência em Segurança Alimentar e Nutricional
              (CERESAN/UFRRJ), “o direito dos povos definirem suas próprias
              políticas e estratégias sustentáveis de produção, distribuição e
              consumo de alimentos que garantam o direito à alimentação para
              toda a população, com base na pequena e média produção,
              respeitando suas próprias culturas e a diversidade dos modos
              camponeses, pesqueiros e indígenas de produção agropecuária, de
              comercialização e gestão dos espaços rurais, nos quais a mulher
              desempenha um papel fundamental […].”(Fórum Mundial sobre
              Soberania Alimentar, Havana, 2001). Já a segurança alimentar e
              nutricional consiste no “direito de todos ao acesso regular e
              permanente a alimentos de qualidade, em quantidade suficiente
              [...]”.
            </p>
            <p>
              <sup>5</sup> Fala da Sra. Maria do Carmo Silva D´ ngelo durante a
              oficina de Cartografia Social, realizada na residência da família,
              localizada na comunidade Paracatu de Cima, em Mariana-MG, em 12 de
              abril de 2018.
            </p>
            <p>
              <sup>6</sup> BRASIL. Ministério da Saúde.
              <strong
                >Guia Alimentar para a População Brasileira promovendo a
                alimentação saudável</strong
              >. Normas e manuais técnicos: Brasília, 2014.
            </p>
            <p>
              <sup>7</sup> Engenheira Agrônoma (UFV), Assessora Técnica do
              Cadastro, Etapa 2 e 3.
            </p>
            <p>
              <sup>8</sup> Engenheiro Agrônomo (UFV), Assessor Técnico do
              Cadastro, Etapa 2 e 3.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudo_soberania_beira_fogao' }"
            color="#EDBD43"
          >
            <strong>Conhecer "Beira do fogão"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: { Card },
};
</script>

<style scoped>
.mb-section {
  margin-bottom: 3em;
}

.mt-section {
  margin-top: 5em;
}

a {
  text-decoration: none;
  color: black;
}

.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-section {
    margin-top: 1em;
  }

  .title__img {
    width: 50%;
  }
  .border__image {
    background-color: #426e51;
    max-width: 50%;
    padding: 0;
    border-radius: 12px;
  }

  .border__image p {
    font-size: 0.6em;
    max-width: 100%;
    padding: 0 !important;
  }

  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: #426e51;
    max-width: 100%;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: #426e51;
    max-width: 50%;
    padding: 0;
    border-radius: 12px;
    bottom: 0;
  }

  .border__image3::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  max-width: 50%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_03.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 2em 2em;
}

.border__image2 {
  max-width: 50%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_06.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 90%;
  padding: 0 0em 4em 5em;
}

.border__image3 {
  max-width: 50%;
  padding: 2em;
  position: relative;
  bottom: -100px;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_cultura_alimentar_07.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 3em 2em 1em 1em;
}
</style>
